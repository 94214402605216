<template>
  <div class="jy-main-litemsr">
    <div class="jy-main-litemsr-top" flex between>
      <p style="font-size: 18px">{{ options.titleR || "--" }}</p>
      <a class="more" @click="getMore(options.titleR)">更多 &gt;</a>
    </div>

    <div
      class="jy-main-litemsr-bottom"
      v-if="options.data_r && options.data_r.length > 0"
    >
      <div
        class="jy-main-litemsr-bottomT show-detail"
        v-for="index in detailNums"
        :key="index"
        flex
        between
      >
        <div @click="book_info(options.data_r[index - 1].id)" class="rank_one">
          <img
            v-if="
              options.data_r[index - 1].cover_image &&
              options.data_r[index - 1].cover_image.match(
                /.*(\.png|\.jpg|\.jpeg|\.gif)$/
              )
            "
            :src="options.data_r[index - 1].cover_image"
            alt=""
            :onerror="errorImg01"
          />
        </div>
        <div flex between column v-if="options.data_r[index - 1]">
          <p
            class="jy-main-litemsr-bottomTN"
            @click="book_info(options.data_r[index - 1].id)"
            words-hidden
            main_p
          >
            {{ options.data_r[index - 1].name || "无" }}
          </p>
          <div b21>
            <p class="jy-main-litemsr-author">
              作者：<span
                @click="
                  options.data_r[index - 1].author_id != 0
                    ? author_info(options.data_r[index - 1].author_id)
                    : ''
                "
                :class="
                  options.data_r[index - 1].author_id
                    ? 'intro_author'
                    : 'un_author'
                "
                >{{ options.data_r[index - 1].author || "无" }}</span
              >
            </p>
            <p
              ellipsis
              v-text="
                options.data_r[index - 1].hasOwnProperty('labels')
                  ? '分类：' + options.data_r[index - 1].labels.name
                  : '分类：' + '无'
              "
            ></p>
          </div>
        </div>
      </div>
      <div
        v-for="index in 9"
        :key="'str' + index"
        class="jy-main-litemsr-bottomB"
        flex
        align
        @click="book_info(options.data_r[index].id)"
        v-show="index > detailNums - 1"
      >
        <span
          class="jy-main-litemsr-bottomS"
          :class="
            index === detailNums
              ? 'show_two'
              : index === detailNums + 1
              ? 'show_three'
              : ''
          "
          >{{ index + 1 }}</span
        >
        <span class="jy-main-litemsr-bottomN" ellipsis v-if="options.data_r">{{
          options.data_r.length > 1 && options.data_r[index]
            ? options.data_r[index].name
            : "暂时空缺"
        }}</span>
      </div>
    </div>
    <div
      class="jy-main-litemsr-bottom"
      v-if="options.data_r && options.data_r.length === 0"
    >
      <div
        class="jy-main-litemsr-bottomT"
        v-for="index in detailNums"
        flex
        between
        :key="index"
      >
        <div :key="'pl' + index">
          <img width="80px" src="../../../assets/images/default.png" alt="" />
        </div>
        <div flex center align>
          <p class="jy-main-litemsr-bottomTN">
            {{ "暂时空缺" }}
          </p>
        </div>
      </div>
      <div
        v-for="index in detailNums"
        :key="'str' + index"
        class="jy-main-litemsr-bottomB"
        flex
        align
        v-show="!index == 0"
      >
        <span
          class="jy-main-litemsr-bottomS"
          :class="index === 1 ? 'show_two' : index === 2 ? 'show_three' : ''"
          >{{ index + 1 }}</span
        >
        <span class="jy-main-litemsr-bottomN" ellipsis>{{ "暂时空缺" }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classify: "",
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
      default_img: require("@/assets/images/default.png"),
    };
  },
  props: {
    options: {
      type: Object,
    },
    detailNums: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    // 书籍详情
    book_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 作者详情
    author_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 更多
    getMore(title) {
      const { href } = this.$router.push({
        path: "/ranking",
        query: {
          title: title,
        },
      });
      // console.log(href,"123***")
      // window.open(href, "_blank");
    },
  },
  mounted() {
    // if(!this.options.data_r[0].cover_image){
    //     this.options.data_r[0].cover_image = this.default_img;
    // }else {
    //     let regx = /[.png]|[.jpg]|[.jpeg]|[.PNG]|[.JPG]|[.JPEG]$/g;
    //     if(!this.options.data_r[0].cover_image.match(regx)){
    //         this.options.data_r[0].cover_image = this.default_img;
    //     }
    // }
  },
};
</script>
<style lang="scss" scoped>
.jy-main-litemsr {
  width: 238px;

  .hidden-box {
    font-size: 14px;
    color: #999;
    margin: 30px 10px;

    .hidden_show {
      cursor: pointer;
      color: #666;
    }

    .hidden_show:hover {
      color: #318dfeff;
    }
  }
}

.jy-main-litemsr-top {
  line-height: 45px;
  border-bottom: 1px solid rgba(228, 228, 228, 1);

  .more {
    width: 58px;
    line-height: 26px;
    color: #d8d8d8;
    font-size: 12px;
    text-align: center;
    border: 1px solid #d8d8d8;
    cursor: pointer;
    align-self: center;
    border-radius: 2px;
    &:hover {
      color: #666;
    }
  }
}

.jy-main-litemsr-bottom {
  margin-top: 20px;
}

.jy-main-litemsr-bottomB {
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  line-height: 33px;
}

.jy-main-litemsr-bottomB:hover {
  color: rgb(49, 141, 254);
}

.jy-main-litemsr-bottomN {
  margin-left: 10px;
}

.jy-main-litemsr-bottomT {
  font-size: 14px;
  &.show-detail {
    margin-bottom: 18px;
  }

  .rank_one {
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    height: 112px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1), 0 0 5px #f9f2e9 inset;

    img {
      cursor: pointer;
      witdth: 100%;
      height: 112px;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }

  .jy-main-litemsr-author {
    margin-bottom: 5px;
    .intro_author {
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
      color: #666;
    }

    .intro_author:hover {
      color: rgba(79, 156, 251, 1);
    }

    .un_author {
      font-size: 12px;
      font-weight: normal;
      color: #666;
    }
  }
}

.jy-main-litemsr-bottomT > div:nth-child(2) {
  flex-grow: 1;
  margin-left: 13px;
}

.jy-main-litemsr-bottomS {
  width: 10%;
  line-height: 33px;
  text-align: center;
}

.jy-main-litemsr-bottomTN {
  width: 100%;
  word-wrap: break-word;
  word-break: normal;
  font-size: 14px;
  cursor: pointer;
}

.jy-main-litemsr-bottomTN:hover {
  color: rgb(49, 141, 254);
}

.show_two {
  background-color: #00acfe;
  margin-right: 4.5px;
}

.show_three {
  background-color: #5ac5fd;
  margin-right: 4.5px;
}

.show_two,
.show_three {
  display: inline-block;
  width: 21px;
  height: 21px;
  line-height: 21px;
  border-radius: 2px;
  color: #fff;
}
</style>
