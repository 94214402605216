<template>
  <div class="jy-book-showitem" @click="book_info(options.id)">
    <div>
      <img
        v-if="
          options.cover_image &&
          options.cover_image.match(/.*(\.png|\.jpg|\.jpeg|\.gif)$/)
        "
        :src="options.cover_image"
        alt=""
        :height="cssStyle.height"
        :width="cssStyle.width"
        :onerror="errorImg01"
      />
      <div class="jy-main-showitem-mask" flex align center>
        <div flex column center align>
          <p>字数：{{ options.total_words }}万</p>
          <p>收藏量：{{ options.total_far }}</p>
          <p>
            人气：{{
              options.click > 99999
                ? (options.click / 10000).toFixed(2) + "w"
                : options.click
            }}
          </p>
        </div>
      </div>
      <VIPShow
        :is_vip="options.is_vip"
        :is_free="options.charge_type"
      ></VIPShow>
    </div>
    <p class="jy-main-bookName" words-hidden main_p>{{ options.name }}</p>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
    },
    cssStyle: {
      type: Object,
    },
  },
  data() {
    return {
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
    };
  },
  methods: {
    // 书籍详情
    book_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {},
};
</script>
<style lang='scss'  scoped>
.jy-book-showitem {
  cursor: pointer;
  position: relative;
  text-align: left;
  margin-bottom: 40px;
  font-size: 12px;
  &:nth-child(n + 6) {
    margin-bottom: 0;
  }
}
.jy-book-showitem > div {
  width: 100%;
  height: 190px;
  background-color: rgba(1, 47, 104, 1);
  border-radius: 2px;
  img {
    border-radius: 2px;
  }
}
.jy-main-showitem-mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  width: 100%;
  height: 190px;
  border-radius: 2px;
  z-index: 100;
}
.jy-main-showitem-mask > div {
  width: 100%;
  height: 100%;
}
.jy-main-showitem-mask > div > p {
  margin: 10px;
  text-align: center;
}
.jy-book-showitem > div:hover .jy-main-showitem-mask {
  display: block;
  color: #ffffff;
  align-items: flex-end;
  background-color: rgba(1, 47, 104, 0.6);
}

.jy-book-showitem > div:hover img {
  opacity: 0.5;
  // border-radius: 5px;
}
.jy-book-showitem > p {
  // font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
.jy-book-showitem > p:hover {
  color: #007aff;
}
.jy-main-bookName {
  font-size: 14px;
}
</style>
