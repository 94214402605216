<template>
    <div class="item show-desc-item" @click="book_info(data.id)">
        <div class="img-con">
            <img :src="data.cover_image" alt="">
        </div>
        <div class="info">
            <div class="title" words-hidden main_p>{{ data.name }}</div>
            <div class="desc" words-hidden book_room_intro>{{ data.intro }}</div>
            <div class="info-bottom">
                <div class="author">作者：
                    <span @click.stop="author_info(data.author_id)">{{ data.author }}</span>
                </div>
                <div class="label">{{ data.book_label }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "showDescItem",
    props: {
        data: {
            type: Object,
            default: function(){
                return {}
            }
        }
    },
    methods: {
        // 书籍详情
        book_info(id) {
            const { href } = this.$router.resolve({
                path: "/show_book",
                query: {
                    id: id,
                },
            });
            window.open(href, "_blank");
        },

        // 作者详情
        author_info(id) {
            const {href} = this.$router.resolve({
                path: "/show_author",
                query: {
                    id: id,
                },
            });
            window.open(href, "_blank");
        },
    }
}
</script>

<style lang="scss" scoped>
.item{
    display: flex;
    width: 279px;
    margin-bottom: 34px;
    align-self: center;
    text-align: left;
    cursor: pointer;
    .img-con{
        min-width: 135px;
        width: 135px;
        height: 190px;
        margin-right: 15px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            &:hover{
                transform: scale(1.2);
                transform-origin: 50% 50%;
                transition: 200ms ease-in-out;
            }
        }
    }
    .info{
        display: flex;
        height: 190px;
        flex-grow: 2;
        flex-direction: column;
        justify-content: space-between;
        .title{
            font-size: 14px;
            line-height: 19px;
            color: #333333;
        }
        .desc{
            height: 4em;
            max-height: 4em;
            flex-grow: 2;
            font-size: 12px;
            line-height: 16px;
            color: #999999;
            overflow: hidden;
        }
        .info-bottom{
            font-size: 12px;
            color: #999999;
            .author{
                margin-bottom: 11px;
                span:hover{
                    color: rgba(79, 156, 251, 1);
                }
            }
        }
    }
}
</style>
