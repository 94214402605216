<template>
  <div class="jy-main-litemsr">
    <div class="jy-main-litemsr-top" flex between>
      <p style="font-size: 18px">{{ options.titleR || "--" }}</p>
      <!-- <a @click="getMore(options.titleR)">更多 &gt</a> -->
    </div>

    <div
      class="jy-main-litemsr-bottom"
      v-if="options.data_r && options.data_r.length > 0"
    >
      <div class="jy-main-litemsr-bottomT" flex between>
        <div>
          <img
            v-if="
              options.data_r[0].avatar &&
              options.data_r[0].avatar.match(/.*(\.png|\.jpg|\.jpeg|\.gif)$/)
            "
            :src="options.data_r[0].avatar"
            alt=""
            width="90"
            height="90"
            :onerror="errorImg01"
          />
        </div>
        <div flex column center align>
          <p class="jy-main-litemsr-bottomTN" ellipsis>
            {{
              options.data_r[0] && options.data_r[0].nickname
                ? options.data_r[0].nickname
                : "无"
            }}
          </p>
        </div>
      </div>
      <div
        v-for="index in 9"
        :key="index"
        class="jy-main-litemsr-bottomB"
        flex
        align
        v-show="!index == 0"
      >
        <span
          class="jy-main-litemsr-bottomS"
          :class="index === 1 ? 'show_two' : index === 2 ? 'show_three' : ''"
          >{{ index + 1 }}</span
        >
        <span class="jy-main-litemsr-bottomN" ellipsis v-if="options.data_r">{{
          options.data_r.length > 1 && options.data_r[index]
            ? options.data_r[index].nickname
            : "暂时空缺"
        }}</span>
      </div>
    </div>
    <div
      class="jy-main-litemsr-bottom"
      v-if="!options.data_r && options.data_r.length === 0"
    >
      <div class="jy-main-litemsr-bottomT" flex between>
        <div>
          <img
            src="../../assets/images/hear_01.jpg"
            alt=""
            width="90px"
            height="90px"
          />
        </div>
        <div flex center align>
          <p class="jy-main-litemsr-bottomTN">
            {{ "暂时空缺" }}
          </p>
        </div>
      </div>
      <div
        v-for="index in 9"
        :key="index"
        class="jy-main-litemsr-bottomB"
        flex
        align
        v-show="!index == 0"
      >
        <span
          class="jy-main-litemsr-bottomS"
          :class="index === 1 ? 'show_two' : index === 2 ? 'show_three' : ''"
          >{{ index + 1 }}</span
        >
        <span class="jy-main-litemsr-bottomN" ellipsis>{{ "暂时空缺" }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classify: "",
      default_img: require("@/assets/images/default.png"),
      errorImg01:
        'this.src="' +
        require("../../../public/static/default_header.png") +
        '"',
    };
  },
  props: {
    options: {
      type: Object,
    },
  },
  methods: {
    // 书籍详情
    book_info(id) {
      sessionStorage.setItem("book_id");
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
      // const { href } = this.$router.resolve({
      //   path: '/show_book',
      //   query: {
      //     id: id,
      //   },
      // });
      // window.open(href, "_blank");
    },
    // 作者详情
    author_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // getMore(title) {
    //   const { href } = this.$router.resolve({
    //     path: `/ranking `,
    //     query: {
    //       title: title,
    //     },
    //   });
    //   window.open(href, "_blank");
    // },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss'  scoped>
.jy-main-litemsr {
  height: 520px;
  width: calc(100% - 75.8% - 40px);
  .hidden-box {
    font-size: 14px;
    color: #999;
    margin: 30px 10px;
    .hidden_show {
      cursor: pointer;
      color: #666;
    }
    .hidden_show:hover {
      color: #318dfeff;
    }
  }
}
.jy-main-litemsr-top {
  line-height: 40px;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  & a {
    cursor: pointer;
    font-size: 14px;
    color: #414141ff;
  }
  & a:hover {
    color: #318dfeff;
  }
}
.jy-main-litemsr-bottom {
  margin-top: 20px;
}
.jy-main-litemsr-bottomB {
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  line-height: 40px;
  margin-top: 2px;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(228, 228, 228, 0.2) 0%,
      rgba(209, 205, 205, 1) 50%,
      rgba(228, 228, 228, 0.2) 100%
    )
    2 2 2 2;
}
.jy-main-litemsr-bottomB:hover {
  color: rgb(49, 141, 254);
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(49, 141, 254, 0.2) 0%,
      rgba(49, 141, 254, 1) 50%,
      rgba(105, 171, 252, 0.2) 100%
    )
    2 2 2 2;
}
.jy-main-litemsr-bottomN {
  margin-left: 10px;
}
.jy-main-litemsr-bottomT {
  width: 90px;
  height: 90px;
  font-size: 14px;
  padding-bottom: 10px;
  &div:nth-of-type(1) {
    img {
      cursor: pointer;
    }
  }
  .jy-main-litemsr-author {
    span {
      cursor: pointer;
    }
    span:hover {
      color: rgb(49, 141, 254);
    }
  }
}

.jy-main-litemsr-bottomT > div:nth-child(2) {
  margin-left: 10px;
}
.jy-main-litemsr-bottomS {
  width: 10%;
  line-height: 40px;
  text-align: center;
}
.jy-main-litemsr-bottomTN {
  width: 135px;
  word-wrap: break-word;
  word-break: normal;
  font-size: 14px;
  cursor: pointer;
}
.jy-main-litemsr-bottomTN:hover {
  color: rgb(49, 141, 254);
}
.show_two {
  background-color: #00acfe;
  margin-right: 4.5px;
}
.show_three {
  background-color: #5ac5fd;
  margin-right: 4.5px;
}
.show_two,
.show_three {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  color: #fff;
}
</style>
