<template>
    <div class="item">
        <div class="title" flex between>
            <h3>{{ title }}</h3>
            <div v-if="!hideMore" class="more" @click="toMore(title)">更多&nbsp;></div>
        </div>
        <ul :class="{ 'rank-list': true, personal: isPersonal }">
            <li v-for="(item, index) in data" :key="item.id" :class="{ personal: item.nickname }" :style="{ cursor: item.nickname ? 'inherit' : 'pointer' }"
                @click="book_info(item)">
                <div class="top3" v-if="index<3 && item.name">
                    <div class="no" >{{ index + 1 }}</div>
                    <div class="cover"><img :src="item.cover_image" alt=""></div>
                    <div class="info">
                        <div class="name" ellipsis>{{ item.name || item.nickname }}</div>
                        <div class="author">作者：{{ item.author }}</div>
                        <div class="label">{{ item.labels && item.labels[0].name }}</div>
                    </div>
                </div>
                <div class="top-3" v-if="index<3 && item.nickname">
                    <div class="no2"><img :src="item.avatar" alt=""></div>
                    <div class="name">{{ item.nickname }}</div>
                </div>
                <div class="after3" v-if="index>2">
                    <div class="no">{{ index + 1 }}</div>
                    <div class="name" ellipsis>{{ item.name || item.nickname }}</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "rankItem",
    props: {
        title: String,
        data: Array,
        isPersonal: Boolean,
        hideMore: Boolean
    },
    data() {
        return {}
    },
    methods: {
        book_info(item) {
            if (item.nickname) return;
            const {href} = this.$router.resolve({
                path: "/show_book",
                query: {
                    id: item.id,
                },
            });
            window.open(href, "_blank");
        },
        toMore(title){
            this.$router.push({
                path: "/ranking",
                query: {
                    title: title,
                },
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    .title {
        padding-left: 34px;
        h3{
            font-size: 20px;
            color: #666;
        }
    }
    .more{
        width: 58px;
        margin-right: 15px;
        line-height: 26px;
        color: #D8D8D8;
        font-size: 12px;
        text-align: center;
        border: 1px solid #D8D8D8;
        cursor: pointer;
        border-radius: 2px;
        &:hover{
            color: #666;
        }
    }
}

.rank-list {
    margin-top: 25px;
    &.personal{
        margin-top: 7px;
    }

    li {
        line-height: 36px;
        list-style: none;

        &.personal{
            .no{
                margin-right: 10px;
                background-color: transparent!important;
                color: #999!important;
            }
        }

        &:nth-child(1) {
            .no {
                background: #FB70AF;
            }

            .no2 {
                background: url("./../../../assets/images/index/1st.png") no-repeat;
                background-size: 100% 100%;
            }
        }

        &:nth-child(2) {
            .no {
                background: #FF9D00;
            }

            .no2 {
                background: url("./../../../assets/images/index/2nd.png") no-repeat;
                background-size: 100% 100%;
            }
        }

        &:nth-child(3) {
            .top-3{
                margin-bottom: 18px;
            }
            .no {
                background: #FFD002;
            }

            .no2 {
                background: url("./../../../assets/images/index/3rd.png") no-repeat;
                background-size: 100% 100%;
            }
        }

        .top3 {
            display: flex;
            margin-bottom: 15px;
            align-items: flex-start;

            .no {
                width: 23px;
                min-width: 23px;
                line-height: 23px;
                text-align: center;
                border-radius: 5px;
                color: #FFF;
            }

            .cover {
                display: flex;
                min-width: 70px;
                max-width: 70px;
                width: 70px;
                height: 98px;
                margin: 0 12px;
                text-align: center;
                align-items: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
                height: 98px;
                flex-grow: 1;
                justify-content: space-between;
                overflow: hidden;

                .name {
                    line-height: 16px;
                    flex-grow: 1;
                }

                .author,
                .label {
                    line-height: 16px;
                    font-size: 12px;
                    color: #666;
                }
                .author{
                    margin-bottom: 12px;
                }

                .name,
                .label {
                    max-width: 100%;
                }
            }
        }

        .top-3 {
            display: flex;
            margin-bottom: 2px;
            align-items: center;

            .no2 {
                display: flex;
                width: 112px;
                height: 112px;
                margin-right: 16px;
                justify-content: center;
                align-items: center;

                img {
                    width: 78px;
                    height: 78px;
                    margin-top: 14px;
                    border-radius: 50%;
                }
            }
        }

        .after3 {
            display: flex;
            align-items: center;

            .no {
                width: 23px;
                margin-right: 12px;
                line-height: 23px;
                text-align: center;
                background: #D8D8D8;
                border-radius: 5px;
                color: #FFF;
            }

            .name {
                flex-grow: 1;
            }
        }
    }
}
</style>
