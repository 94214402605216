import { render, staticRenderFns } from "./main-item.vue?vue&type=template&id=5d576427&scoped=true&"
import script from "./main-item.vue?vue&type=script&lang=js&"
export * from "./main-item.vue?vue&type=script&lang=js&"
import style0 from "./main-item.vue?vue&type=style&index=0&id=5d576427&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d576427",
  null
  
)

export default component.exports