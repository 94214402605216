<template>
    <div class="jy-main-litems" flex between>
        <mainLitemsL :options='options'></mainLitemsL>
        <userVip :options='options'></userVip>
    </div>
</template>
<script>
import mainLitemsL from './lists/main-litemL';
import userVip from './user_vip';
export default {
    data() {
        return {
            
        }
    },
    components:{
        mainLitemsL,
        userVip
    },
    props: {
        options: {
            type:Object
        }
    },
    mounted() {
    },
}
</script>
<style  lang='scss' scoped>
    .jy-main-litems {
        width: 100%;
        height: 500px;
        display: flex;
    }
</style>