<template>
  <div class="jy-main" flex column align>
    <navList />
    <div class="container">
      <!-- binnar  and  notice and  workeCommend -->
      <div flex between>
        <div class="jy-main-lunbo">
          <div class="block">
            <el-carousel height="365px" :interval="3000">
              <el-carousel-item
                v-for="item in get_lunbo_banner_info"
                :key="item.id"
              >
                <img
                  :src="item.img_url"
                  alt=""
                  width="100%"
                  height="365"
                  @click="to_link(item, true)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="notice-author">
          <div class="author-center">
            <h3>作者中心</h3>
            <div class="items" flex>
              <div class="item" @click="openUrl(1)">
                <img src="./../../assets/images/index/author1.png" alt="" />
                <div class="name">签约福利</div>
              </div>
              <div class="item" @click="openUrl(2)">
                <img src="./../../assets/images/index/author2.png" alt="" />
                <div class="name">问题</div>
              </div>
              <div class="item" @click="openUrl(3)">
                <img src="./../../assets/images/index/author3.png" alt="" />
                <div class="name">投稿</div>
              </div>
            </div>
          </div>
          <div class="jy-main-notice">
            <h3>公告</h3>
            <el-carousel height="162px" direction="vertical" :interval="50000">
              <el-carousel-item
                v-for="(item, index) in this.optionsLunbo.noticeArr"
                :key="'str' + index"
              >
                <ul>
                  <li
                    v-for="it in item"
                    :key="it.id"
                    @click="show_photo(it.type, it.url, it.id)"
                  >
                    <div class="title">{{ it.text }}</div>
                    <div class="note">鲸云轻小说编辑部</div>
                  </li>
                </ul>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!--        <workeCommend :options="options_work"></workeCommend>-->
      </div>
    </div>
    <div class="container">
      <!--好书推荐-->
      <div class="jy-main-lists">
        <span class="anchoPoint" id="goodBook"></span>
        <mainLitems :options="optionsArr[4]"></mainLitems>
      </div>

      <div class="banner-notice">
        <a href="javascript:;" @click="to_link(get_mid_banner_info)">
          <img :src="get_mid_banner_info.img_url" alt="" />
        </a>
      </div>

      <!-- 综合热门 -->
      <div class="jy-main-lists" marginb style="margin-top: 0">
        <span class="anchoPoint" id="allHot"></span>
        <mainLitems
          :showMore="true"
          :detailNums="2"
          :options="optionsArr[0]"
        ></mainLitems>
      </div>

      <!-- 小编推荐 -->
      <div class="jy-main-lists" marginb style="margin-top: 10px">
        <span class="anchoPoint" id="authorRec"></span>
        <mainLitems :showDesc="true" :options="optionsArr[1]"></mainLitems>
      </div>

      <!-- 主力推荐 -->
    </div>
    <div class="jy-main-hr" flex between align>
      <p class="jy-main-hrp"></p>
      <p class="jy-main-hrif" flex center align>
        <span class="anchoPoint" id="hostRec"></span>
        <img
          style="width: 203px"
          src="./../../assets/images/index/mainrecommend.png"
          alt=""
        />
      </p>
      <p class="jy-main-hrp"></p>
    </div>
    <div class="jy-main-goodbook" marginb>
      <goodbook :options="optionsGoodBook"></goodbook>
    </div>

    <div class="container">
      <!-- 作品推荐 -->
      <div class="jy-main-lists" marginb>
        <span class="anchoPoint" id="workRecA"></span>
        <mainLitems :detailNums="2" :options="optionsArr[3]"></mainLitems>
      </div>
      <!-- 最新更新 -->
      <div class="jy-main-lists" marginb>
        <span class="anchoPoint" id="lastUpdateA"></span>
        <mainLitems
          :showMore="true"
          :detailNums="2"
          :options="optionsArr[2]"
        ></mainLitems>
      </div>
    </div>

    <bottomRank :readerCostRank="readerCostRank" />
  </div>
</template>
<script>
import workeCommend from "./work-recommend";
// import mainLunbo from "./lunbo";
import mainLitems from "./main-Lists";
import goodbook from "./main-goodbook";
import mainbook from "./main-mainbook";
import mainbottom from "./main-bottom";
import newBook from "./new_book";
import navList from "./mainbooks/nav";
import bottomRank from "./mainbooks/bottomRank";
// import "../../../public/static/swiper.min.css";

import { home_one, home_two, home_three } from "@/https/home.js";
import { mapGetters } from "vuex";

export default {
  components: {
    workeCommend,
    // mainLunbo,
    mainLitems,
    goodbook,
    mainbook,
    mainbottom,
    newBook,
    navList,
    bottomRank,
  },
  data() {
    return {
      timer: null,
      // 轮播图
      optionsLunbo: {
        imgsrc: [],
        noticeArr: [],
      },
      optionsArr: [
        // 综合热门
        {
          l_id: 1,
          r_id: 1,
          titleL: "综合热门榜",
          titleR: "热销榜",
          icon: require("./../../assets/images/index/hot.png"),
          style: "color:rgb(249, 76, 3);font-size:25px",
          data_l: [],
          data_r: [],
        },
        {
          id: 2,
          r_id: 2,
          titleL: "小编推荐",
          titleR: "新书潜力榜",
          icon: require("./../../assets/images/index/recommend.png"),
          style: "color:rgb(18, 150, 219);font-size:25px",
          data_l: [],
          data_r: [],
        },
        {
          id: 3,
          r_id: 3,
          titleL: "最新更新榜",
          titleR: "人气完本榜",
          icon: require("./../../assets/images/index/newupdate.png"),
          style: "color:rgb(52, 85, 245);font-size:25px",
          data_l: [],
          data_r: [],
        },
        {
          id: 4,
          r_id: 4,
          titleL: "作品推荐",
          titleR: "免费热门榜",
          icon: require("./../../assets/images/index/workrecommend.png"),
          style: "color:rgb(196, 8, 181);font-size:25px",
          data_l: [],
          data_r: [],
        },
        {
          id: 5,
          titleL: "好书推荐",
          icon: require("./../../assets/images/index/goodbook.png"),
        },
      ],
      optionsGoodBook: {
        data: [],
        icon: "",
      },
      // 作品推荐
      options_work: [],
      // 底部信息
      options_bottom: {
        title: [],
        footer_photo: [],
      },
      // 主力推荐
      options_main: {
        data_l: [],
        data_r: [],
        icon: "icon-tuijian",
        style: "color:rgb(30, 194, 255);font-size:25px",
      },
      data_r: [],
      readerCostRank: [],
    };
  },
  provide() {
    return {};
  },
  created() {
    // 获取数据
    this.load_data_three();
    this.load_data_two();
    this.load_data_one();
    sessionStorage.removeItem("cur_id");
  },
  computed: {
    ...mapGetters(["get_mid_banner_info", "get_lunbo_banner_info"]),
  },
  methods: {
    to_link(item, poster) {
      if (item.content_type === 1) {
        const { href } = this.$router.resolve({
          path: "/show_book",
          query: {
            id: item.content,
          },
        });
        window.open(href, "_blank");
        return;
      } else if (item.content_type === 2 && poster) {
        window.open(
          "https://book.jyacg.com/show_posters?link=" + item.content,
          "_blank"
        );
        return;
      }
      if (item.content) {
        window.open(item.content, "_blank");
      }
    },
    openUrl(index) {
      let urls = [
        "https://book.jyacg.com/fuli.html",
        "https://guide.jyacg.com/#/Q&A",
        "https://guide.jyacg.com/#/contribute",
      ];
      window.open(urls[index - 1]);
    },
    // 公告、banner跳转
    go_link(item) {
      if (item.is_posters == 1) {
        this.$router.push({
          path: "/show_posters",
          query: {
            link: item.link,
          },
        });
      } else {
        window.open(item.link);
      }
    },
    // 公告、banner跳转
    show_photo(type, url, id) {
      if (url) {
        if (type === 2) {
          const { href } = this.$router.resolve({
            path: "/show_photo",
            query: {
              id: id,
            },
          });
          window.open(href, "_blank");
        } else {
          // this.go_link(url);
          window.open(url, "_blank");
        }
      }
    },
    // 加载数据接口1
    load_data_one() {
      home_one().then((res) => {
        // console.log(res.data)
        // 轮播图
        res.data.banner.forEach((element) => {
          if (element.url) {
            this.optionsLunbo.imgsrc.push(element);
          }
        });
        // this.optionsLunbo.imgsrc = res.data.banner;
        // 公告
        let noticeList = [];
        let result = [];
        res.data.notice.forEach((el) => {
          if (el.text) {
            noticeList.push(el);
          }
        });
        while (noticeList.length) {
          result.push(noticeList.splice(0, 3));
        }
        this.optionsLunbo.noticeArr = result;

        // 好书推荐;
        this.optionsArr[4] = {
          ...this.optionsArr[4],
          data_l: (res.data.good_recommend || []).splice(0, 6),
        };
        // 综合热门
        this.optionsArr[0] = {
          ...this.optionsArr[0],
          data_l: (res.data.hot_top || []).splice(0, 12),
        };
        // 新书潜力榜
        this.optionsArr[1] = {
          ...this.optionsArr[1],
          data_r: (res.data.new_top || []).splice(0, 10),
        };
        this.init_swiper();
        setTimeout(() => this.$forceUpdate);
      });
      // 方法三：在main.vue中获取公告
    },
    // 加载数据接口2
    load_data_two() {
      home_two().then((res) => {
        // 底部信息
        this.options_bottom.title[0] = res.data.title1;
        this.options_bottom.title[1] = res.data.title2;
        this.options_bottom.title[2] = res.data.title3;
        this.options_bottom.title[3] = res.data.title4;
        this.options_bottom.footer_photo = res.data.footer_photo;
        this.options_bottom.footer_photo = this.options_bottom.footer_photo.splice(
          0,
          4
        );
        // 最新更新榜
        this.optionsArr[2] = {
          ...this.optionsArr[2],
          data_l: (res.data.update_top || []).splice(0, 12),
        };

        // 主力推荐
        this.options_main.data_l = res.data.main_recommend;
        this.optionsGoodBook = {
          ...this.optionsGoodBook,
          data: (res.data.main_recommend || []).splice(0, 6),
        };

        this.options_main.data_l = this.options_main.data_l.splice(0, 6);
        setTimeout(() => this.$forceUpdate);
      });
    },
    // 加载数据接口3
    load_data_three() {
      home_three().then((res) => {
        // 作品推荐 免费热门
        this.optionsArr[3] = {
          ...this.optionsArr[3],
          data_l: (res.data.home_recommend || []).splice(0, 12),
          data_r: (res.data.free_top || []).splice(0, 10),
        };
        // 热销榜
        this.optionsArr[0] = {
          ...this.optionsArr[0],
          data_r: (res.data.hot_order_top || []).splice(0, 12),
        };

        this.optionsArr[1] = {
          ...this.optionsArr[1],
          data_l: (res.data.editor_recommend || []).splice(0, 12),
        };

        //读者消费榜
        this.readerCostRank = (res.data.money_top || []).splice(0, 10);
        // 人气完本榜
        this.optionsArr[2] = {
          ...this.optionsArr[2],
          data_r: (res.data.over_top || []).splice(0, 10),
        };
        // 主编推荐
        this.data_r = res.data.editor_recommend;
        this.data_r = this.data_r.splice(0, 9);

        setTimeout(() => this.$forceUpdate);
        // console.log('*********',this.data_r)
      });
    },
    // 初始化swiper   公告
    init_swiper() {
      let that = this;
      this.timer = setTimeout(() => {
        var mySwiper1 = new Swiper(".swiper-container2", {
          loop: true, //循环播放
          slidesPerView: 3,
          spaceBetween: 0, //滑块间隔
          direction: "horizontal",
          on: {
            click: function (e) {
              if (e.target.className == "jy-main-notice-content") {
                let row = JSON.parse(e.target.getAttribute("data-row"));
                that.show_photo(row.type, row.url, row.id);
              }
            },
          },
          autoplay: {
            delay: 6000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            // reverseDirection: true,
          }, //自动播放
          noSwiping: true, //禁止手动滑动
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: false, //修改swiper的父元素时，自动初始化swiper
          onSlideChangeEnd: function (swiper) {
            // 更新Swiper，这个方法包含了updateContainerSize，updateSlidesSize，updateProgress，updatePagination，updateClasses方法。也就是数据改变是重新初始化一次swiper；
            swiper.update();
            //   重新开始自动切换；
            mySwiper1.startAutoplay();
            // 重新对需要循环的slide个数进行计算，当你改变了slidesPerView参数时需要用到，需要自动轮播的时候必须要加上；
            mySwiper1.reLoop();
          },
        });
        var container = document.getElementById("swiper_container2");
        this.$nextTick(() => {
          if (container) {
            container.onmouseenter = function () {
              mySwiper1.autoplay.stop();
            };
            container.onmouseleave = function () {
              mySwiper1.autoplay.start();
            };
          }
        });
      }, 500);
    },
  },
  async mounted() {
    // 数据加载后再初始化公告
    await this.$nextTick(() => {});
  },
  beforeDestroy() {
    // 清楚定时器
    clearTimeout(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.jy-main {
  position: relative;
  width: 100%;
  background-color: #fafcff;
  padding-top: 20px;
}

.jy-main-lunbo {
  width: 941px;
  height: 365px;

  img {
    border-radius: 4px 4px 0 0;
    cursor: pointer;
  }
}

.notice-author {
  width: 238px;

  .author-center {
    display: flex;
    width: 100%;
    height: 132px;
    padding: 10px 12px 14px;
    flex-direction: column;
    justify-content: space-between;
    background: url(./../../assets/images/index/authorcenter.png) no-repeat;
    background-size: 100% 100%;

    h3 {
      font-size: 16px;
    }

    .item {
      width: 33.3333%;
      cursor: pointer;
      text-align: center;

      img {
        width: 42px;
        height: 42px;
        margin-bottom: 12px;
      }

      .name {
        color: #666;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .jy-main-notice {
    width: 100%;
    margin-top: 15px;
    padding: 12px 9px;
    border: 1px solid #d8d8d8;
    opacity: 1;
    border-radius: 10px;

    h3 {
      font-size: 15px;
      margin-bottom: 10px;
    }

    /deep/ .el-carousel__indicator {
      .el-carousel__button {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #d8d8d8;
      }

      &.is-active {
        .el-carousel__button {
          background-color: #666666;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      margin: 4px 0 0;
      padding-right: 40px;
      height: 144px;
      justify-content: space-between;

      li {
        position: relative;
        line-height: 22px;
        cursor: pointer;
        padding: 4px 0 4px 15px;
        border-bottom: 1px dashed #999;

        &:before {
          display: inline-block;
          content: "";
          position: absolute;
          left: 0;
          top: 13px;
          width: 6px;
          height: 6px;
          background: #409eff;
        }

        &:nth-last-child(1) {
          padding-bottom: 0;
          border-bottom: none;
        }

        .title,
        .note {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .title {
          color: #409eff;
          font-size: 14px;
        }

        .note {
          font-size: 12px;
        }
      }
    }
  }
}

.jy-main-lists {
}

.jy-main-mainbook {
  width: 100%;
  margin-bottom: 0;
  background-color: #fff;
}

.jy-main-goodbook {
  width: 100%;
  height: 337px;
  background: #2b3339;
}

.banner-notice {
  margin-top: -12px;
  img {
    width: 100%;
    height: 147px;
    border: 1px solid #eee;
  }
}

.anchoPoint {
  position: relative;
  display: inline-block;
  z-index: -1;
  background: red;
  transform: translateY(-120px);
}
</style>
