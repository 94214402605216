<template>
    <div class="nav-container">
        <img src="./../../../assets/images/index/navTopBg.png" alt=""/>
        <ul>
            <li :class="activeCode === item.code ? 'active' : ''"
                v-for="item in list"
                @click="gotoDom(item.code)"
                :key="item.code">
                <a href="javascript:;">{{ item.name }}</a>
            </li>
            <li @click="gotoTop">
                <img src="./../../../assets/images/index/gotop.png" alt=""/>
                <img class="hover" src="./../../../assets/images/index/gotop2.png" alt=""/>
            </li>
        </ul>
    </div>
</template>

<script>
const IDS = ["goodBook", "allHot", "authorRec", "hostRec", "workRecA", "lastUpdateA"];
export default {
    name: "nav",
    data() {
        return {
            activeCode: 'goodBook',
            stopHashChange: false,
            list: [
                {code: 'goodBook', name: '好书推荐'},
                {code: 'allHot', name: '综合热门榜'},
                {code: 'authorRec', name: '小编推荐'},
                {code: 'hostRec', name: '主力推荐'},
                {code: 'workRecA', name: '作品推荐'},
                {code: 'lastUpdateA', name: '最近更新'},
            ]
        }
    },
    mounted() {
        this.getActiveIndex();
        document.body.onscroll = this.getActiveIndex;
        window.onhashchange = this.getActiveIndex;
    },
    beforeDestroy() {
        document.body.onscroll = null;
        window.onhashchange = null;
    },
    methods: {
        getActiveIndex() {
            if (this.stopHashChange) return;
            for (let i = 0; i < IDS.length; i++) {
                let item = IDS[i];
                let distance = document.getElementById(item).offsetTop - (document.body.scrollTop || document.documentElement.scrollTop);
                if (distance > -80 && distance < 120) {
                    // location.hash = item;
                    this.activeCode = item;
                    break;
                }
            }
        },
        gotoTop() {
            this.activeCode = 'goodBook';
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        gotoDom(code) {
            let dom = document.getElementById(code);
            if (dom.scrollIntoView) {
                dom.scrollIntoView({
                    behavior: 'smooth'
                });
                this.stopHashChange = true;
                this.timer && clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.stopHashChange = false;
                }, 800);
                setTimeout(() => {
                    this.activeCode = code;
                }, 400);
            } else {
                location.hash = code;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-container {
    position: fixed;
    width: 91px;
    line-height: 40px;
    right: 80px;
    left: calc((100% - 1200px) / 2 + 1280px);
    top: 50%;
    margin-top: 23px;
    transform: translateY(-50%);
    z-index: 200;

    & > img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        transform: translateY(-100%);
    }

    ul {
        margin: 0;
        border: 1px solid #ddd;
        border-radius: 10px;
        background-color: #FFF;
        overflow: hidden;

        li {
            list-style: none;
            text-align: center;
            font-size: 12px;
            overflow: hidden;
            border-bottom: 1px solid #dedede;

            &:hover {
                background: #7ABBFF;
                a{
                    color: #FFF!important;
                }

                img {
                    display: none;

                    &.hover {
                        display: block;
                    }
                }
            }

            img {
                display: block;

                &.hover {
                    display: none;
                }
            }

            a {
                display: inline-block;
                width: 100%;
                color: #333 !important;
            }

            &.active {
                background: #7ABBFF;

                a {
                    color: #FFF !important;
                }
            }

            &:nth-last-child(1) {
                height: 66px;
                border: none
            }

            img {
                width: 23px;
                margin: 20px auto;
                cursor: pointer;
            }
        }
    }
}

@media screen and(max-width: 1542px) {
    .nav-container {
        right: 0;
        left: calc((100% - 1200px) / 2 + 1220px);
    }
}

@media screen and(max-width: 1420px) {
    .nav-container {
        display: none;
        right: 0;
        left: calc((100% - 1200px) / 2 + 1220px);
    }
}
</style>
