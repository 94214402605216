<template>
  <div class="jy-main-gbooklists container" flex align between>
    <div
      class="jy-book-showitem"
      v-for="item in options.data"
      :key="item.id"
      @click="book_info(item.id)"
    >
      <div>
        <img
          v-if="
            item.cover_image &&
            item.cover_image.match(/.*(\.png|\.jpg|\.jpeg|\.gif)$/)
          "
          :src="item.cover_image"
          height="245.5px"
          width="175px"
          :onerror="errorImg01"
        />
        <div class="jy-main-showitem-mask" flex align center>
          <div flex column center align>
            <p>字数：{{ item.total_words }}万</p>
            <p>收藏：{{ item.total_far }}人</p>
            <p>
              人气：{{
                item.click > 99999
                  ? (item.click / 10000).toFixed(2) + "w"
                  : item.click
              }}
            </p>
          </div>
        </div>
        <VIPShow
          :is_vip="item.is_vip"
          :is_free="item.charge_type"
          :style="{ fontSize: '14px' }"
        ></VIPShow>
      </div>
      <p class="jy-main-bookName" main_p words-hidden>{{ item.name }}</p>
    </div>
  </div>
</template>
<script>
import mainItem from "./lists/main-item";

export default {
  components: {
    mainItem,
  },
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      itemArr: [],
      errorImg01:
        'this.src="' + require("../../../public/static/default_book.png") + '"',
    };
  },
  methods: {
    getItemArr() {
      // 从后台获取好友推荐榜单数据,限制为6条
    },
    // 改变部分样式
    changeStyle() {
      let book_name = document.querySelectorAll(
        ".jy-main-gbooklists .jy-main-bookName"
      );
      for (let i = 0; i < book_name.length; i++) {
        book_name[i].style.cssText = "font-size:16px;color:#fff;";
      }
    },
    // 书籍详情
    book_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  async mounted() {
    // await this.changeStyle();
  },
};
</script>
<style lang='scss' scoped>
.jy-main-gbooklists {
  height: 100%;
  padding-top: 20px;
}

.jy-main-gbooklists {
  .jy-main-showitem-mask {
    width: 175.1px;
    height: 245.5px;
  }

  .jy-book-showitem {
    cursor: pointer;
    position: relative;
    text-align: left;
    margin-bottom: 15px;
    font-size: 12px;
    width: 175px;
    .jy-main-bookName {
      height: 40px;
    }

    > div {
      width: 175px;
      height: 245.5px;
      background-color: rgba(1, 47, 104, 1);
    }

    .jy-main-showitem-mask {
      display: none;
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      font-size: 12px;
      width: 100%;
      height: 245.5px;

      > div {
        width: 100%;
        height: 100%;

        > p {
          margin: 10px;
          text-align: center;
        }
      }
    }

    > div:hover .jy-main-showitem-mask {
      display: block;
      color: #ffffff;
      align-items: flex-end;
      background-color: rgba(1, 47, 104, 0.5);
    }

    > div:hover img {
      opacity: 0.5;
    }

    > p {
      color: #ffffff;
      font-size: 16px;
      margin-top: 14px;
      text-align: center;
    }
  }

  .jy-book-showitem > p:hover {
    color: #007aff;
  }
}
</style>