<template>
    <div class="index_bottom_rank_con">
        <div class="index_bottom_rank">
            <div class="pay-rank">
                <rankItem title="付费热门榜" :data="payRank"/>
            </div>
            <div class="ticket-rank">
                <rankItem title="月票榜" :data="ticketRank"/>
            </div>
            <div class="collect-rank">
                <rankItem title="收藏榜" :data="collectRank"/>
            </div>
            <div class="cost-rank">
                <rankItem :hideMore="true" title="读者消费榜" :isPersonal="true" :data="readerCostRank"/>
            </div>
        </div>
    </div>
</template>

<script>
import rankItem from './rankItem';
import {book_roon} from '@/https/stack_room';

export default {
    name: "bottomRank",
    props: {
        readerCostRank: Array
    },
    components: {
        rankItem
    },
    data() {
        return {
            payRank: [],
            ticketRank: [],
            collectRank: [],
            typeDataKey: {
                xlzg: 'payRank',
                ypzg: 'ticketRank',
                sczd: 'collectRank',
            }
        }
    },
    mounted() {
        this.getData('xlzg');
        this.getData('ypzg');
        this.getData('sczd');
    },
    methods: {
        async getData(type) {
            let par = {
                page: 1,
                limit: 10,
                tag: type
            };
            let res = await book_roon(par);
            if (res.code === 0) {
                this[this.typeDataKey[type]] = res.data || [];
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.index_bottom_rank_con {
    width: 100%;
    padding-top: 12px;
    background-color: #FFF;
}

.index_bottom_rank {
    display: flex;
    width: 1200px;
    margin: 0 auto 80px;
    justify-content: space-between;

    & > div {
        width: 25%;
        /deep/ ul{
            padding: 0 25px;
            border-right: 1px solid #D8D8D8;
        }

        &:nth-child(1) {
            /deep/ ul{
                padding-left: 0;
            }
        }

        &:nth-last-child(1) {
            /deep/ ul{
                padding-right: 0;
                border-right: 1px solid transparent;
            }
        }
    }
}
</style>
