<template>
    <div class="jy-main-mainbooks container" flex between>
        <mainbooksL :options='options'></mainbooksL>
        <mainBookR :options='main_right' v-if="main_right.length"></mainBookR>
    </div>
</template>
<script>
import { home_one, home_two, home_three } from "@/https/home.js";
import mainbooksL from './mainbooks/mainbooksL';
import mainBookR from './mainbooks/mainbookeItemR';
export default {
    components:{
       mainbooksL,
       mainBookR
    },
    data() {
        return {
            main_right:[]
        }
    },
    props:{
        options:{
            type:Object
        },
        data_r:{
            type:Array
        }
    },
    created() {
        home_three().then(res => {
            let main_right = res.data.editor_recommend;
            this.main_right = main_right.splice(0,9)
        })
    }
}
</script>
<style lang='scss'  scoped>
    .jy-main-mainbooks {
        height: 100%;
    }
</style>