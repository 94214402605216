var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jy-main-litemsr"},[_c('div',{staticClass:"jy-main-litemsr-top",attrs:{"flex":"","between":""}},[_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.options.titleR || "--"))]),_c('a',{staticClass:"more",on:{"click":function($event){return _vm.getMore(_vm.options.titleR)}}},[_vm._v("更多 >")])]),(_vm.options.data_r && _vm.options.data_r.length > 0)?_c('div',{staticClass:"jy-main-litemsr-bottom"},[_vm._l((_vm.detailNums),function(index){return _c('div',{key:index,staticClass:"jy-main-litemsr-bottomT show-detail",attrs:{"flex":"","between":""}},[_c('div',{staticClass:"rank_one",on:{"click":function($event){return _vm.book_info(_vm.options.data_r[index - 1].id)}}},[(
            _vm.options.data_r[index - 1].cover_image &&
            _vm.options.data_r[index - 1].cover_image.match(
              /.*(\.png|\.jpg|\.jpeg|\.gif)$/
            )
          )?_c('img',{attrs:{"src":_vm.options.data_r[index - 1].cover_image,"alt":"","onerror":_vm.errorImg01}}):_vm._e()]),(_vm.options.data_r[index - 1])?_c('div',{attrs:{"flex":"","between":"","column":""}},[_c('p',{staticClass:"jy-main-litemsr-bottomTN",attrs:{"words-hidden":"","main_p":""},on:{"click":function($event){return _vm.book_info(_vm.options.data_r[index - 1].id)}}},[_vm._v(" "+_vm._s(_vm.options.data_r[index - 1].name || "无")+" ")]),_c('div',{attrs:{"b21":""}},[_c('p',{staticClass:"jy-main-litemsr-author"},[_vm._v(" 作者："),_c('span',{class:_vm.options.data_r[index - 1].author_id
                  ? 'intro_author'
                  : 'un_author',on:{"click":function($event){_vm.options.data_r[index - 1].author_id != 0
                  ? _vm.author_info(_vm.options.data_r[index - 1].author_id)
                  : ''}}},[_vm._v(_vm._s(_vm.options.data_r[index - 1].author || "无"))])]),_c('p',{attrs:{"ellipsis":""},domProps:{"textContent":_vm._s(
              _vm.options.data_r[index - 1].hasOwnProperty('labels')
                ? '分类：' + _vm.options.data_r[index - 1].labels.name
                : '分类：' + '无'
            )}})])]):_vm._e()])}),_vm._l((9),function(index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index > _vm.detailNums - 1),expression:"index > detailNums - 1"}],key:'str' + index,staticClass:"jy-main-litemsr-bottomB",attrs:{"flex":"","align":""},on:{"click":function($event){return _vm.book_info(_vm.options.data_r[index].id)}}},[_c('span',{staticClass:"jy-main-litemsr-bottomS",class:index === _vm.detailNums
            ? 'show_two'
            : index === _vm.detailNums + 1
            ? 'show_three'
            : ''},[_vm._v(_vm._s(index + 1))]),(_vm.options.data_r)?_c('span',{staticClass:"jy-main-litemsr-bottomN",attrs:{"ellipsis":""}},[_vm._v(_vm._s(_vm.options.data_r.length > 1 && _vm.options.data_r[index] ? _vm.options.data_r[index].name : "暂时空缺"))]):_vm._e()])})],2):_vm._e(),(_vm.options.data_r && _vm.options.data_r.length === 0)?_c('div',{staticClass:"jy-main-litemsr-bottom"},[_vm._l((_vm.detailNums),function(index){return _c('div',{key:index,staticClass:"jy-main-litemsr-bottomT",attrs:{"flex":"","between":""}},[_c('div',{key:'pl' + index},[_c('img',{attrs:{"width":"80px","src":require("../../../assets/images/default.png"),"alt":""}})]),_c('div',{attrs:{"flex":"","center":"","align":""}},[_c('p',{staticClass:"jy-main-litemsr-bottomTN"},[_vm._v(" "+_vm._s("暂时空缺")+" ")])])])}),_vm._l((_vm.detailNums),function(index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!index == 0),expression:"!index == 0"}],key:'str' + index,staticClass:"jy-main-litemsr-bottomB",attrs:{"flex":"","align":""}},[_c('span',{staticClass:"jy-main-litemsr-bottomS",class:index === 1 ? 'show_two' : index === 2 ? 'show_three' : ''},[_vm._v(_vm._s(index + 1))]),_c('span',{staticClass:"jy-main-litemsr-bottomN",attrs:{"ellipsis":""}},[_vm._v(_vm._s("暂时空缺"))])])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }