<template>
  <div class="jy-main-litemsr">
    <div class="jy-main-litemsr-top" flex between>
      <p style="font-size: 18px">小编推荐</p>
      <!-- <a href="#" @click="getMore('小编推荐')">更多 &gt</a> -->
    </div>
    <div class="jy-main-litemsr-b">
      <div v-if="options && options.length > 0">
        <div class="jy-main-litemsr-T" flex between>
          <!-- 小编推荐 -->
          <div @click="book_info(options[0].id)" class="rank_one">
            <img
              :src="options[0].cover_image"
              alt=""
              height="120px"
              width="85px"
              :onerror="errorImg01"
            />
          </div>
          <div flex between column>
            <p
              words-hidden
              main_p
              class="jy-main-litemsr-TN"
              @click="book_info(options[0].id)"
            >
              {{ options[0] && options[0].name ? options[0].name : "无" }}
            </p>
            <div b21>
              <p class="jy-main-litemsr-author">
                作者：<span
                  @click="
                    options[0].author ? author_info(options[0].author_id) : ''
                  "
                  :class="options[0].author_id ? 'intro_author' : 'un_author'"
                  >{{ options[0].author || "无" }}</span
                >
              </p>
              <p
                style="max-width: 135px"
                ellipsis
                v-text="
                  options[0].labels
                    ? '分类：' + options[0].labels.name
                    : '分类：无'
                "
              ></p>
            </div>
          </div>
        </div>
        <div
          v-for="index in 8"
          :key="index"
          class="jy-main-litemsr-B"
          flex
          align
          @click="book_info(options[index].id)"
          v-show="!index == 0"
        >
          <span
            class="jy-main-litemsr-S"
            :class="index === 1 ? 'show_two' : index === 2 ? 'show_three' : ''"
            >{{ index + 1 }}</span
          >
          <span class="jy-main-litemsr-N" ellipsis>{{
            options.length > 1 && options[index]
              ? options[index].name
              : "暂时空缺"
          }}</span>
        </div>
      </div>
      <div v-if="options.data_r && options.data_r.length === 0">
        <div class="jy-main-litemsr-T" flex between>
          <div>
            <img
              src="../../../assets/images/default.png"
              alt=""
              height="120px"
              width="85px"
            />
          </div>
          <div flex between column>
            <p class="jy-main-litemsr-TN">
              {{ "暂时空缺" }}
            </p>
          </div>
        </div>
        <div
          v-for="index in 8"
          :key="index"
          class="jy-main-litemsr-B"
          flex
          align
        >
          <!-- v-text="item.labels ? item.labels[9].name:'无'" -->
          <span
            class="jy-main-litemsr-S"
            :class="index === 1 ? 'show_two' : index === 2 ? 'show_three' : ''"
            >{{ index + 1 }}</span
          >
          <span class="jy-main-litemsr-N" ellipsis>{{ "暂时空缺" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classify: "",
      default_img: require("@/assets/images/default.png"),
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
    };
  },
  props: {
    options: {
      type: Array,
    },
  },
  methods: {
    // 书籍详情
    book_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 作者详情
    author_info(id) {
      const { href } = this.$router.resolve({
        path: "/show_author",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 更多
    getMore(title) {
      this.$router.push({
        path: "/ranking",
        query: {
          title: title,
        },
      });
      // window.open(href, "_blank");
    },
  },
  mounted() {
    // if((typeof this.options.data_r[0].labels) == 'object'){
    //     this.classify = this.options.data_r[0].labels[9].name;
    // }
    // console.log( '小编右边',  this.options)
  },
};
</script>
<style lang='scss'  scoped>
.jy-main-litemsr {
  height: 520px;
  width: calc(100% - 75.8% - 40px);
}
.jy-main-litemsr-top {
  line-height: 40px;
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  & a {
    font-size: 12px;
    color: #414141ff;
  }
  & a:hover {
    color: #318dfeff;
  }
}
.jy-main-litemsr-b {
  margin-top: 20px;
  // cursor: pointer;
  .hidden-box {
    font-size: 14px;
    color: #999;
    margin: 30px 10px;
    .hidden_show {
      cursor: pointer;
      color: #666;
    }
    .hidden_show:hover {
      color: #318dfeff;
    }
  }
}
.jy-main-litemsr-B {
  cursor: pointer;
  font-size: 14px;
  line-height: 31px;
  margin-top: 2px;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(228, 228, 228, 0.2) 0%,
      rgba(209, 205, 205, 1) 50%,
      rgba(228, 228, 228, 0.2) 100%
    )
    2 2 2 2;
  border-image: -webkit-linear-gradient(
      to right,
      rgba(228, 228, 228, 0.2) 0%,
      rgba(209, 205, 205, 1) 50%,
      rgba(228, 228, 228, 0.2) 100%
    )
    2 2 2 2;
}
.jy-main-litemsr-B:hover {
  color: #318dfeff;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(49, 141, 254, 0.2) 0%,
      rgba(49, 141, 254, 1) 50%,
      rgba(105, 171, 252, 0.2) 100%
    )
    2 2 2 2;
  border-image: -webkit-linear-gradient(
      to right,
      rgba(49, 141, 254, 0.2) 0%,
      rgba(49, 141, 254, 1) 50%,
      rgba(105, 171, 252, 0.2) 100%
    )
    2 2 2 2;
}
.jy-main-litemsr-N {
  margin-left: 10px;
}
.jy-main-litemsr-T {
  // width: 120px;
  // height: 130px;
  // font-size: 14px;
  padding-bottom: 10px;
  .rank_one {
    width: 85px;
    height: 120px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 0 5px #f9f2e9 inset;
    img {
      cursor: pointer;
      width: 85px;
      height: 120px;
      transition: all 0.5s;
    }
    img:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  & div:nth-of-type(2) {
    .jy-main-litemsr-author {
      .intro_author {
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        color: #666;
      }
      .intro_author:hover {
        color: rgba(79, 156, 251, 1);
      }
      .un_author {
        font-size: 12px;
        font-weight: normal;
        color: #666;
      }
      // span {
      //   cursor: pointer;
      // }
      // span:hover {
      //   color: #318dfeff;
      // }
    }
  }
}

.jy-main-litemsr-T > div:nth-child(2) {
  margin-left: 10px;
  width: 135px;
}
.jy-main-litemsr-S {
  width: 10%;
  line-height: 31px;
  text-align: center;
}
.jy-main-litemsr-TN {
  width: 135px;
  word-wrap: break-word;
  word-break: normal;
  font-size: 14px;
  cursor: pointer;
}
.jy-main-litemsr-TN:hover {
  color: #318dfeff;
}
.show_two {
  background-color: #00acfe;
  margin-right: 4.5px;
}
.show_three {
  background-color: #5ac5fd;
  margin-right: 4.5px;
}
.show_two,
.show_three {
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 2px;
  color: #fff;
}
</style>