<template>
  <div class="jy-main-commend">
    <p
      style="
        font: 18px 'microsoft yahei', \u5fae\u8f6f\u96c5\u9ed1, '微软雅黑',
          Arial;
      "
    >
      作品推荐
    </p>
    <div class="jy-main-commend-item">
      <div
        v-for="(item, index) in options"
        :key="item.id"
        flex
        class="item_show"
      >
        <p flex center align>
          <span :class="[index === 0 ? 'rank_one':'',index === 1 ? 'rank_two':'',index === 2 ? 'rank_three':'',index > 2 ? 'rank_item':'']"
            v-text="index <= 2 ? index + 1 : '【' + (index + 1) + '】'"
            >
          </span>
        </p>
        <span @click="book_info(item.id)" ellipsis>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classArr: [],
    };
  },
  props: {
    options: {
      type: Array,
    },
  },
  methods: {
    // 书籍详情
    book_info(id) {
      sessionStorage.setItem("book_id", id);
      // this.$router.push({
      //     path:'/show_book',
      //     query:{
      //         id:id
      //     }
      // })
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  mounted() {
    // this.options.sort((a,b) => {
    //     return b.id - a.id;
    // })
  },
};
</script>
<style lang='scss'  scoped>
.jy-main-commend {
  // width: 20.9%;
  width: calc(100% - 75.8% - 40px);
  height: 406px;
  background-color: #fff;
  padding-left: 7px;
  text-align: left;
  border-radius: 5px;
}
.jy-main-commend > p {
  // color: #414141;
  color: #000000;
  height: 40px;
  padding: 10px 0 5px 0;
  font-size: 18px;
  // font-weight: bold;
  // font-weight: normal;
}
.item_show {
  font-size: 14px;
  margin: 5px 0;
  line-height: 30px;
  width: calc(100% - 7px);
  cursor: pointer;
  & p {
    display: inline-block;
    width: 45px;
    text-align: center;
  }
  > span {
    width: calc(100% - 45px);
    cursor: pointer;
  }
  > span:last-of-type:hover {
    color: #318dfe;
  }

}
.rank_two {
    background-color: #00acfe ;
  }
  .rank_three {
    background-color: #5ac5fd ;
  }
  .rank_one {
    background-color: #0076ea ;
  }
  .rank_item {
    display: inline-block ;
    text-align: center;
  }
  .rank_two,
  .rank_one,
  .rank_three {
    display: inline-block ;
    width: 18px ;
    height: 18px ;
    border-radius: 2px ;
    color: #fff ;
    text-align: center;
    line-height: 18px;
    margin-right: 4.5px ;
  }
</style>
