<template>
    <div class="jy-main-mainbooksL">
        <div class="jy-main-litemsl-top" flex between>
            <p style="font-size: 18px">
                <span class="iconfont" :class="options.icon" :style="options.style"></span>
                <span>主力推荐</span>
            </p>
            <!-- <a @click="getMore('主力推荐')">更多 &gt</a> -->
        </div>
        <div class="jy-main-mainbooksLB" flex between wrap align v-if="options.data_l && options.data_l.length > 0">
            <div

                v-for="(item, index) in options.data_l"
                :key="index"
                class="jy-main-mainbooksLB-items"
                flex
                between
                align
                v-show="options.data_l"
            >
                <div class="jy-main-mainbooksLB-itemsL">
                    <mainItem :cssStyle="cssStyle" :options="item"></mainItem>
                </div>
                <div class="jy-main-mainbooksLBR" flex between column>
                    <div>
                        <p class="jy-main-mainbooksLBR-bName" @click="book_info(item.id)" words-hidden one-clamp>
                            {{ item.name || "无" }}
                        </p>
                    </div>
                    <div>
                        <p class="jy-main-mainbooksLBR-bInfo" words-hidden book_room_intro>{{ item.intro || "无" }}</p>
                    </div>
                    <div class="jy-main-mainbooksLBR-bottom" flex column between>
                        <!-- style="font-size: 12px; cursor: pointer" -->
                        <p @click="item.author_id ? author_info(item.author_id):''" words-hidden one-clamp
                           :class="item.author_id ? 'intro_author':'un_author'" style="color:#666;">
                            作者：<span style="color:#666;">{{ item.author || "无" }}</span>
                        </p>

                        <!-- v-text="(typeof item.labels === 'object') ? '分类：'+item.labels[9].name: '分类：'+'无'" -->
                        <p
                            style="font-size: 12px;color:#666;"
                            words-hidden one-clamp
                            v-text="
                 (item &&typeof item.labels === 'object')
                  ? '分类：' + item.labels.name 
                  : '分类：' + '无'
              "
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mainItem from "../lists/main-item";

export default {
    components: {
        mainItem,
    },
    props: {
        options: {
            type: Object,
        },
    },
    data() {
        return {
            cssStyle: {
                width: "135px",
                height: "190px",
            },
            classify: [],
        };
    },
    methods: {
        // 更多
        getMore(title) {
            this.$router.push({
                path: "/ranking",
                query: {
                    title: title
                }
            });
        },
        // 书籍详情
        book_info(id) {
            const {href} = this.$router.resolve({
                path: "/show_book",
                query: {
                    id: id,
                },
            });
            window.open(href, '_blank');
        },
        // 作者详情
        author_info(id) {
            const {href} = this.$router.resolve({
                path: "/show_author",
                query: {
                    id: id
                }
            });
            window.open(href, "_blank");
        },
    },
    created() {
    },
    mounted() {
    },
};
</script>
<style lang='scss' scoped>
.jy-main-mainbooksL {
    width: 75.8%;
    height: 100%;

    .hidden-box {
        // display: inline-block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        font-size: 12px;
        color: #999;
        margin: 30px 10px;
        text-align: center;

        .hidden_show {
            cursor: pointer;
            color: #666;
        }

        .hidden_show:hover {
            color: #318dfeff;
        }
    }
}

.jy-main-litemsl-top {
    width: 100%;
    line-height: 40px;
    font-weight: bold;
    border-bottom: 1px solid rgba(228, 228, 228, 1);

    & a {
        cursor: pointer;
        font-size: 12px;
        color: #414141ff;
    }

    & a:hover {
        color: #318dfeff;
    }

    & p:nth-of-type(1) {
        & span:nth-of-type(1) {
            margin-right: 5px;
            font-size: 18px;
        }
    }
}

.jy-main-mainbooksLB {
    width: 100%;
    margin-top: 20px;

}

.jy-main-mainbooksLB-items {
    width: calc((96% - 40px) / 3);
    margin-bottom: 20px;
}

.jy-main-mainbooksLB-itemsL {
    // width: 44.5%;
    width: 52%;
    height: 190px;
    overflow: hidden;
}

.jy-main-mainbooksLB-itemsL {
    .jy-main-showitem-mask {
        width: 135px;
        height: 190px;
    }

    .jy-book-showitem {
        width: 135px;
    }
}

.jy-main-mainbooksLBR p {
    font-size: 12px;
    word-wrap: break-word;
    word-break: normal;
}

.jy-main-mainbooksLBR {
    margin-left: 10px;
    width: 50%;
    height: 190px;
}

.jy-main-mainbooksLBR-bName {
    cursor: pointer;
    font-size: 16px !important;
    // -webkit-line-clamp:2 !important;
}

.jy-main-mainbooksLBR-bName:hover {
    color: #318dfe;
}

.jy-main-mainbooksLBR-bInfo {
    color: #666;
    font-size: 14px !important;
}

.jy-main-mainbooksLBR-bottom {
    p {
        margin: 10px 0;
        font-size: 14px !important;

        .intro_author {
            font-size: 12px;
            font-weight: normal;
            cursor: pointer;
            color: #666;
        }

        .intro_author:hover {
            color: rgba(79, 156, 251, 1);
        }

        .un_author {
            font-size: 12px;
            font-weight: normal;
            color: #666;
        }
    }
}
</style>