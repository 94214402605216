<template>
    <div class="jy-main-litemsl">
        <div class="jy-main-litemsl-top" flex between>
            <p style="font-size: 18px">
                <img :src="options.icon" alt="">
                <span>{{ options.titleL }}</span>
            </p>
            <a v-if="showMore" class="more" @click="getMore(options.titleL)">更多 &gt;</a>
        </div>
        <div class="jy-main-litemsl-bottom" v-if="!showDesc">
            <mainItem
                v-for="item in options.data_l"
                :key="item.id"
                :cssStyle="cssStyle"
                :options="item"
            ></mainItem>
        </div>
        <div class="jy-main-litemsl-bottom" v-if="showDesc">
            <showDescItem
                v-for="item in options.data_l"
                :key="item.id"
                :data="item"
            ></showDescItem>
        </div>
    </div>
</template>
<script>
import mainItem from "./main-item";
import showDescItem from "./showDescItem";

export default {
    components: {
        mainItem,
        showDescItem
    },
    data() {
        return {
            keyArr: [],
            cssStyle: {
                width: "135px",
                height: "190px",
            },
            //   default_img: require("@/assets/images/default.png"),
        };
    },
    props: {
        options: {
            type: Object
        },
        showMore: Boolean,
        showDesc: Boolean,
    },
    mounted() {
    },
    methods: {
        //根据不同的id从后台获取数据
        // 更多
        getMore(title) {
            this.$router.push({
                path: "/ranking",
                query: {
                    title: title,
                },
            });
            // window.open(href, "_blank");
        },
        // 书籍详情
        book_info(id) {
            const {href} = this.$router.push({
                path: "/show_book",
                query: {
                    id: id,
                },
            });
            window.open(href, "_blank");
        },
    },
};
</script>
<style lang='scss' scoped>
.jy-main-litemsl {
    width: 941px;
}

.jy-main-litemsl-top {
    font-weight: bold;
    line-height: 40px;
    .more{
        width: 58px;
        line-height: 26px;
        color: #D8D8D8;
        font-size: 12px;
        text-align: center;
        border: 1px solid #D8D8D8;
        cursor: pointer;
        align-self: center;
        border-radius: 2px;
        &:hover{
            color: #666;
        }
    }

    & p:nth-of-type(1) {
        display: flex;
        align-items: center;
    }

    img {
        width: 45px;
        height: 45px;
        margin-right: 11px;
    }
    span{
        color: #666;
    }
}

.jy-main-litemsl-bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    .jy-main-showitem-mask {
        width: 135px;
        height: 190px;
    }

    .jy-book-showitem {
        width: 135px;
    }

}
</style>
