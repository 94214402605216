<template>
    <div class="jy-main-litems" flex between>
        <mainLitemsL :showDesc="showDesc" :showMore="showMore" :options='options'></mainLitemsL>
        <mainLitemsR :detailNums="detailNums" :options='options' v-if="options.r_id"></mainLitemsR>
        <div v-else class="school">
            <div class="title" flex>
                <h3>鲸云学院</h3>
                <div class="desc">一起来写轻小说吧！</div>
            </div>
            <a href="javascript:;" @click="to_link(get_ad_banner_info)">
                <img :src="get_ad_banner_info.img_url" alt="">
            </a>
        </div>
    </div>
</template>
<script>
import mainLitemsL from './lists/main-litemL';
import mainLitemsR from './lists/main-litemR';
import {mapGetters} from "vuex";

export default {
    data() {
        return {}
    },
    components: {
        mainLitemsL,
        mainLitemsR
    },
    props: {
        options: {
            type: Object
        },
        detailNums: Number, //排行顶部显示书籍信息的数量
        showMore: Boolean,  //是否显示更多的按钮
        showDesc: Boolean, //书本为显示详情的样式 如小编推荐的
    },
    computed: {
        ...mapGetters(['get_ad_banner_info'])
    },
    mounted() {
    },
    methods: {
        to_link(item) {
            if(item.content){
                window.open(item.content);
            }
        },
    }
}
</script>
<style lang='scss' scoped>
.jy-main-litems {
    width: 100%;
    display: flex;
}

.school {
    width: 238px;
    margin-bottom: 18px;
    background: #FFFFFF;
    border-radius: 10px;
    align-self: flex-start;

    .title {
        padding: 0 15px;
        line-height: 40px;
        white-space: nowrap;

        h3 {
            font-size: 18px;
        }

        .desc {
            margin-left: 20px;
            font-size: 13px;
        }
    }

    img {
        width: 100%;
        height: 234px;
        margin-top: 5px;
    }
}
</style>
